import React from 'react'
import {graphql} from 'gatsby'
import SEO from '../components/SEO/SEO'
import Layout from '../components/Layout/Layout'
import '../styles/app.scss'
import logoSmall from '../images/Logo_MS_72.png'

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO />
        <section className="mucsafe-home--section1">
            <img src={logoSmall} alt="logo-ms" />
            <h1>Munich Safety </h1>
        </section>
          <section className="mucsafe-home--section2">
              <h3>Die Munich Safety GmbH ist eine unabhängige Prüfinstanz für funktionale Sicherheit.<br/><br/>
                  Wir führen vorrangig Prüftätigkeiten durch. Zusätzlich geben wir unser Wissen in Trainings weiter und stehen unseren Kunden mit Beratung und interim Safety Management zur Seite.<br/><br/>
                  Die Munich Safety legt viel Wert auf tatsächlich erreichte Sicherheit und wirtschaftlichen Mehrwert durch nachhaltige Entwicklung. Wir nehmen gerne die Safety Manager unserer Kunden an die Hand und zeigen Ihnen, worauf es bei der Umsetzung der funktionalen Sicherheit in Ihrem Unternehmen ankommt.<br/><br/>
                  Unsere Fachgebiete sind die ISO 26262 und ASPICE, sowohl in klassischen Systemen als auch in Kombination mit autonomen Fahren und agiler Entwicklung.<br/><br/>
                  <a href="mailto:info@munichsafety.de">Kontaktieren Sie uns</a> für ein unverbindliches persönliches Gespräch.
              </h3>
          </section>
      </Layout>
    )
  }
}

export default IndexPage

export const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
